import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

export default function OtpVerificationDialog({
  openProp,
  handleClose,
  userAddress,
  ...other
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(openProp);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpRefs = useRef([]);
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    setOpen(openProp);
    if (openProp) {
      startTimer();
    }
  }, [openProp]);

  const startTimer = () => {
    setTimer(60);
    setCanResend(false);

    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleChange = (index, value) => {
    if (!/^[0-9]?$/.test(value)) return; // Allow only numbers

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        otpRefs.current[index - 1].focus();
      }
    }
  };

  // Submit OTP
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const otpCode = otp.join("");
    if (otpCode.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/verify-otp/${userAddress}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ otp_code: Number(otpCode) }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        toast.error(error.message);
        setOtp(Array(6).fill(""));
        return;
      }

      const result = await response.json();
      toast.success(result.message);

      await handleUserNFTToken();
      handleClose();
      navigate("/home");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to verify OTP");
    } finally {
      setLoading(false);
    }
  };

  const handleUserNFTToken = async () => {
    const checkNft = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}auth/check-usernft`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          walletAddress: userAddress,
        }),
      }
    );
    const nftResult = await checkNft.json();

    if (nftResult) {
      const nftTokenId = parseInt(nftResult.data.tokenId, 10);
      localStorage.setItem("nftdata", String(nftTokenId));
      localStorage.setItem("token", nftResult.data.token);
    } else {
      toast.error("Failed to get user details");
    }
  };
  // Resend OTP
  const handleResendOtp = async () => {
    try {
      setCanResend(false);
      setTimer(60);
      setOtp(Array(6).fill(""));
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/resend-otp/${userAddress}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        const error = await response.json();
        toast.error(error.message);
        return;
      }

      toast.success("OTP resent successfully");
      startTimer();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to resend OTP");
      setCanResend(true);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 500 } }}
        PaperProps={{ component: "form", onSubmit: handleSubmit }}
        {...other}
      >
        <Toaster />
        <DialogTitle sx={{ textAlign: "center" }}>OTP Verification</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" justifyContent="center" gap={1}>
            {otp.map((value, index) => (
              <TextField
                key={index}
                value={value}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                inputRef={(el) => (otpRefs.current[index] = el)}
                type="text"
                variant="outlined"
                size="small"
                sx={{
                  width: 40,
                  height: 40,
                  "& input": {
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                  },
                }}
                inputProps={{
                  maxLength: 1,
                }}
              />
            ))}
          </Box>

          {/* Timer */}
          {!canResend ? (
            <Typography
              variant="body2"
              sx={{ textAlign: "center", mt: 6, color: "black" }}
            >
              Resend OTP in <strong>{timer}s</strong>
            </Typography>
          ) : (
            <MDBox display="flex" justifyContent="center" mt={6}>
              <MDButton
                onClick={handleResendOtp}
                variant="outlined"
                sx={{
                  color: "#40811E",
                  borderColor: "#40811E",
                  "&:hover": { backgroundColor: "#40811E", color: "#fff" },
                }}
              >
                Resend OTP
              </MDButton>
            </MDBox>
          )}
        </DialogContent>

        <DialogActions>
          <MDBox display="flex" justifyContent="center" width="100%">
            <MDButton
              type="submit"
              disabled={loading}
              variant="contained"
              sx={{
                background:
                  "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                color: "#fff",
                "&:hover": { backgroundColor: "darkgreen" },
              }}
            >
              {loading ? "Verifying..." : "Verify OTP"}
            </MDButton>
          </MDBox>
        </DialogActions>
      </Dialog>
    </>
  );
}

OtpVerificationDialog.propTypes = {
  openProp: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userAddress: PropTypes.string.isRequired,
};
