import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import ApprovedImage from "assets/images/icons/Approved.png";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
function SuccessDialog({
  open,
  handleClose,
  title,
  message,
  redirectUrl,
  description,
}) {
  const navigate = useNavigate();
  const onClickRedirect = () => {
    navigate(redirectUrl);
  };
  return (
    <MDBox display="flex" justifyContent="center" mb={4}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
        fullWidth={true} // Ensures the dialog stretches as per the content
      >
        <MDBox display="flex" flexDirection="column" alignItems="center" p={6}>
          <img
            alt="document"
            src={ApprovedImage}
            width={80}
            style={{ marginBottom: "16px" }}
          />
          <DialogTitle id="error-dialog-title" align="center">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="error-dialog-description" align="center">
              {message}
            </DialogContentText>
            <DialogContentText
              id="error-dialog-description"
              align="center"
              mt={2}
              sx={{ color: "red", fontSize: 18 }}
            >
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={onClickRedirect}
              variant="contained"
              size="large"
              sx={{
                background:
                  "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
            >
              Continue
            </MDButton>
          </DialogActions>
        </MDBox>
      </Dialog>
    </MDBox>
  );
}

export default SuccessDialog;
