import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Tooltip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  Typography,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ErrorDialog from "components/Dialog/error";
import SuccessDialog from "components/Dialog/success";
import { useNavigate } from "react-router-dom";
import "./custom.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";
import PaymentHashErrorMsg from "components/Dialog/PaymentHashErrorMsg";
import TronHashImage from "assets/images/tron-hash.png";
import ICBXADDRESSIMAGE from "assets/images/icbx-address.png";
import { ethers } from "ethers";

function NFTPayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const [hash, setHash] = useState("");
  const [address, setAddress] = useState("");
  const [formError, setFormError] = useState(false);

  const [titleDialogMessage, setTitleDialogMessage] = useState("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isErrorPaymentHashDialogOpen, setIsErrorPaymentHashDialogOpen] =
    useState(false);
  const [selectedPayment, setSelectedPayment] = useState("Tron");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { loading } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getUserProfileData(token));
    } else {
      navigate("/login");
    }
  }, [dispatch, token, navigate]);

  // const isValidTransactionHash = (input) => /^[a-fA-F0-9]{64}$/.test(input);
  const isValidWalletAddress = (input) => /^0x[a-fA-F0-9]{40}$/.test(input);

  const handleAddressCopy = (address, addressType) => {
    navigator.clipboard.writeText(address).then(() => {
      toast.success(`${addressType} address copied to clipboard!`);
    });
  };

  const handlePaymentFormSubmit = async (event) => {
    event.preventDefault();
    let errors = {};
    // if (!isValidTransactionHash(hash)) {
    //   errors.hash =
    //     "Invalid transaction hash. Must be 64 hexadecimal characters.";
    // }
    if (!isValidWalletAddress(address)) {
      errors.address =
        "Invalid wallet address. Must start with '0x' followed by 40 hexadecimal characters.";
    }

    if (Object.keys(errors).length > 0) {
      setFormError(errors);
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/nft-payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            hash,
            secondaddress: address,
            payment_method: selectedPayment,
          }),
        }
      );

      if (response.ok) {
        await response.json();
        setTitleDialogMessage("Payment Submitted");
        setSuccessMessage(
          "Your payment request has been successfully submitted. Please wait for further processing."
        );
        setIsSuccessDialogOpen(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || "An unknown error occurred. Please try again."
        );
        setTitleDialogMessage("Payment Error");
        setIsErrorDialogOpen(true);
        setIsErrorPaymentHashDialogOpen(true);
      }
    } catch (error) {
      setErrorMessage(
        "There was an issue submitting your request. Please check your connection and try again."
      );
      setTitleDialogMessage("Submission Error");
      setIsErrorDialogOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const handleDialogErrorPaymentHashClose = () => {
    setIsErrorPaymentHashDialogOpen(false);
  };

  const paymentData = {
    Tron: {
      image: TronHashImage,
      address: process.env.REACT_APP_TRANSACTIONCODE,
      label: "Pay USDT on Tron Blockchain",
    },
    ICBX: {
      image: ICBXADDRESSIMAGE,
      address: process.env.REACT_APP_ICBX_ADDRESS,
      label: "Pay TRX on ICBX Blockchain",
    },
    Coupon: {
      image: ICBXADDRESSIMAGE,
      address: process.env.REACT_APP_ICBX_ADDRESS,
      label: "Pay TRX on ICBX Blockchain",
    },
  };

  const checkAndVerifyAddress = async (event) => {
    event.preventDefault();

    if (!isValidWalletAddress(address)) {
      setFormError({
        address:
          "Invalid wallet address. Must start with '0x' followed by 40 hexadecimal characters.",
      });
      return;
    }

    try {
      setIsSubmitting(true);

      if (!window.ethereum) {
        throw new Error("MetaMask not installed");
      }

      // ✅ Ensure the wallet is connected
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      // console.log(accounts);
      if (
        !accounts.map((a) => a.toLowerCase()).includes(address.toLowerCase())
      ) {
        throw new Error("This address does not match in any of your accounts.");
      }

      const message = `Sign this message to verify coupon. Wallet address: ${address}`;
      const signature = await window.ethereum.request({
        method: "personal_sign",
        params: [ethers.hexlify(ethers.toUtf8Bytes(message)), address],
      });

      // console.log("Signature:", signature);

      const responseSign = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/verify-signature-NFTPayment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            walletAddress: address,
            signature: signature,
            message: message,
          }),
        }
      );

      const resultSign = await responseSign.json();

      if (resultSign.code === 1) {
        toast.success("Signature verified successfully!");
        navigate("/profile");
      } else {
        toast.error(resultSign.error?.message || "Verification failed");
      }
    } catch (error) {
      toast.error(`  ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Toaster />
      <MDBox pt={6} pb={3}>
        {loading ? (
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
          >
            <CircularProgress size={60} color="primary" />
            <Typography variant="h6" ml={2}>
              Loading your data...
            </Typography>
          </MDBox>
        ) : (
          <>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={10} lg={8} textAlign="center">
                <MDTypography
                  variant="h1"
                  sx={{ fontSize: { xs: "1.8rem", md: "2.2rem" } }}
                  align="center"
                >
                  Make a payment to this address from any wallet.
                </MDTypography>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <FormControl>
                  <RadioGroup
                    row
                    value={selectedPayment}
                    onChange={(e) => setSelectedPayment(e.target.value)}
                  >
                    <FormControlLabel
                      value="Tron"
                      control={<Radio />}
                      label="Pay by Tron"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 50,
                        },
                        "& .MuiFormControlLabel-label": {
                          fontSize: "20px",
                          color: "#d1062a",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="ICBX"
                      control={<Radio />}
                      label="Pay by ICBX"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 50,
                        },
                        "& .MuiFormControlLabel-label": {
                          fontSize: "20px",
                          color: "#d1062a",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="Coupon"
                      control={<Radio />}
                      label="Coupon"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 50,
                        },
                        "& .MuiFormControlLabel-label": {
                          fontSize: "20px",
                          color: "#d1062a",
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {selectedPayment && selectedPayment !== "Coupon" ? (
                <Grid item xs={12} textAlign="center">
                  <MDTypography
                    variant="h3"
                    mb={2}
                    sx={{ fontSize: { xs: "1.1rem", md: "2.2rem" } }}
                  >
                    Scan QR Code!
                  </MDTypography>
                  {/* <img
                      alt="document"
                      src={DocumentDarkImage}
                      style={{
                        cursor: "pointer",
                        width: isMobile ? "35%" : 130,
                      }}
                    /> */}
                  <img
                    src={paymentData[selectedPayment].image}
                    alt="payment"
                    style={{
                      width: isMobile ? "60%" : 150,
                      cursor: "pointer",
                    }}
                  />
                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt={2}
                  >
                    <MDTypography
                      variant="h6"
                      sx={{ wordBreak: "break-all", maxWidth: "80%" }}
                    >
                      {paymentData[selectedPayment].address}
                    </MDTypography>
                    <Tooltip title="Copy">
                      <IconButton
                        onClick={() =>
                          handleAddressCopy(
                            paymentData[selectedPayment].address,
                            selectedPayment
                          )
                        }
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  <MDTypography variant="h5" color="info" mt={2}>
                    {paymentData[selectedPayment].label}
                  </MDTypography>

                  <MDBox
                    component="form"
                    onSubmit={handlePaymentFormSubmit}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                    mt={3}
                  >
                    <TextField
                      required
                      label="Transaction Hash"
                      placeholder="Enter or paste your transaction hash here"
                      error={!!formError.hash}
                      helperText={formError.hash}
                      value={hash}
                      onChange={(e) => setHash(e.target.value)}
                      sx={{ width: { xs: "90%", sm: "75%", md: "50%" } }}
                    />

                    <TextField
                      required
                      label="Wallet Address"
                      placeholder="Enter or paste your wallet address here"
                      error={!!formError.address}
                      helperText={formError.address}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      sx={{ width: { xs: "90%", sm: "75%", md: "50%" } }}
                    />

                    <MDButton
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{
                        background:
                          "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                        color: "#fff",
                        "&:hover": { backgroundColor: "darkgreen" },
                        m: 2,
                      }}
                    >
                      {isSubmitting ? (
                        <MDBox>
                          <CircularProgress size={20} color="white" />
                          <MDTypography variant="text" color="white" ml={2}>
                            Submitting...
                          </MDTypography>
                        </MDBox>
                      ) : (
                        "Submit"
                      )}
                    </MDButton>
                  </MDBox>
                </Grid>
              ) : (
                <Grid item xs={12} textAlign="center">
                  <MDBox
                    component="form"
                    onSubmit={checkAndVerifyAddress}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                    mt={8}
                  >
                    <TextField
                      required
                      label="Wallet Address"
                      placeholder="Enter or paste your wallet address here"
                      error={!!formError.address}
                      helperText={formError.address}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      sx={{ width: { xs: "90%", sm: "75%", md: "50%" } }}
                    />

                    <MDButton
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{
                        background:
                          "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                        color: "#fff",
                        "&:hover": { backgroundColor: "darkgreen" },
                        m: 2,
                      }}
                    >
                      {isSubmitting ? (
                        <MDBox>
                          <CircularProgress size={20} color="white" />
                          <MDTypography variant="text" color="white" ml={2}>
                            Submitting...
                          </MDTypography>
                        </MDBox>
                      ) : (
                        "Submit"
                      )}
                    </MDButton>
                  </MDBox>{" "}
                </Grid>
              )}
            </Grid>
          </>
        )}
      </MDBox>
      <ErrorDialog
        open={isErrorDialogOpen}
        handleClose={handleDialogClose}
        title={titleDialogMessage}
        message={errorMessage}
      />
      <SuccessDialog
        open={isSuccessDialogOpen}
        handleClose={handleDialogSuccessClose}
        title={titleDialogMessage}
        message={successMessage}
        redirectUrl="/profile"
      />
      <PaymentHashErrorMsg
        openProp={isErrorPaymentHashDialogOpen}
        handleClose={handleDialogErrorPaymentHashClose}
      />
    </DashboardLayout>
  );
}

export default NFTPayment;
