import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import toast, { Toaster } from "react-hot-toast";
import OtpVerificationDialog from "components/Dialog/OtpVerificationDialog";
export default function EmailVerificationDialog({
  openProp,
  handleClose,
  userAddress,
  ...other
}) {
  const [open, setOpen] = useState(openProp);
  const [loading, setLoading] = useState(false);
  const [otpVerificationPopup, setOtpVerification] = useState(false);

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.currentTarget);
    const { email } = Object.fromEntries(formData.entries());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/update-user-email/${userAddress}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        toast.error(error.message);
        return;
      }

      const result = await response.json();

      toast.success(result.message);

      setOtpVerification(true);
      setOpen(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to update email");
    } finally {
      setLoading(false);
    }
  };

  const handleDialogSuccessClose = () => {
    setOtpVerification(false);
    handleClose();
  };

  return (
    <>
      <Toaster />
      <Dialog
        open={open}
        maxWidth="sm"
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        PaperProps={{ component: "form", onSubmit: handleSubmit }}
        {...other}
      >
        <Toaster />
        <DialogTitle sx={{ textAlign: "center" }}>
          Email Confirmation
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            required
            id="email"
            name="email"
            placeholder="Enter your email address here"
            type="email"
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <MDBox display="flex" justifyContent="center" width="100%">
            <MDButton
              type="submit"
              disabled={loading}
              variant="contained"
              sx={{
                background:
                  "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                color: "#fff",
                "&:hover": { backgroundColor: "darkgreen" },
              }}
            >
              {loading ? "Submitting..." : "Submit"}
            </MDButton>
          </MDBox>
        </DialogActions>
      </Dialog>
      <OtpVerificationDialog
        openProp={otpVerificationPopup}
        handleClose={handleDialogSuccessClose}
        message="success"
        userAddress={userAddress}
      />
    </>
  );
}

EmailVerificationDialog.propTypes = {
  openProp: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userAddress: PropTypes.string.isRequired,
};
