import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Alert,
  AlertTitle,
  CircularProgress,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TronHashImage from "assets/images/tron-hash.png";
import ICBXADDRESSIMAGE from "assets/images/icbx-address.png";
import ErrorDialog from "components/Dialog/error";
import SuccessDialog from "components/Dialog/success";
import { useNavigate } from "react-router-dom";
import ProgressBar from "components/ProgressBar";
import "./custom.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";
import { STATUS_MESSAGE, PAYMENTSTATUSMESSAGES } from "constants";
import Marquee from "react-fast-marquee";
import PaymentHashErrorMsg from "components/Dialog/PaymentHashErrorMsg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function Payment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [userData, setUserData] = useState(null);
  const [titleDialogMessage, setTitleDialogMessage] = useState("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // const [formError, setFormError] = useState(false);
  const [hash, setHash] = useState("");
  const [expiredTimer, setExpiredTimer] = useState("");
  const [descriptionMessage, setDescriptionMessage] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("Tron");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isErrorPaymentHashDialogOpen, setIsErrorPaymentHashDialogOpen] =
    useState(false);

  const { userDetails, loading } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getUserProfileData(token));
    } else {
      navigate("/login");
    }
  }, [dispatch, token, navigate]);

  useEffect(() => {
    if (userDetails !== null) {
      setUserData(userDetails);
    }
  }, [userDetails]);

  const handleDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const handleAddressCopy = (address, addressType) => {
    navigator.clipboard.writeText(address).then(() => {
      toast.success(`${addressType} address copied to clipboard!`);
    });
  };

  const checkDocumentStatus = () => {
    if (userData?.isResubmitted === true && userData?.isPayment === true) {
      setTitleDialogMessage("Success");
      setSuccessMessage(
        "You don't have to worry about payment because you've already made it."
      );
      setIsSuccessDialogOpen(true);
      return false;
    }

    const validations = [
      {
        condition: userData?.isDocument === false,
        message:
          "You must submit the passport document before you can proceed for payment.",
        title: "Document Upload Incomplete",
      },
      {
        condition: userData?.isFace === false,
        message:
          "Before making a payment, you must complete face identification.",
        title: "Face Verification Incomplete",
      },
      {
        condition: userData?.isPhoto === false,
        message: "Before making a payment, a photo must be uploaded.",
        title: "Photo Upload Incomplete",
      },
    ];

    for (const validation of validations) {
      if (validation.condition) {
        setIsErrorDialogOpen(true);
        setErrorMessage(validation.message);
        setTitleDialogMessage(validation.title);
        return false;
      }
    }

    const statusActions = {
      Under_review: {
        dialogType: "success",
        title: "Under Review",
        message: STATUS_MESSAGE.DOCUMENT.UNDER_REVIEW,
      },
      Processing: {
        dialogType: "success",
        title: "Creating NFT",
        message: STATUS_MESSAGE.DOCUMENT.CREATE_NFT,
        userDescription: userData?.description,
      },
      Block: {
        dialogType: "error",
        title: "Block",
        message: STATUS_MESSAGE.DOCUMENT.BLOCK,
        userDescription: userData?.description,
      },
      Rejected: {
        dialogType: "error",
        title: "Rejected",
        message: STATUS_MESSAGE.DOCUMENT.REJECTED,
        userDescription: userData?.description,
      },
      Completed: {
        dialogType: "success",
        title: "Completed",
        message: STATUS_MESSAGE.DOCUMENT.COMPLETED,
        userDescription: userData?.description,
      },
    };
    const action = statusActions[userData?.status];

    if (action) {
      if (action.dialogType === "success") {
        setIsSuccessDialogOpen(true);
        setTitleDialogMessage(action.title);
        setSuccessMessage(action.message);
        setDescriptionMessage(action.userDescription);
      } else {
        setIsErrorDialogOpen(true);
        setTitleDialogMessage(action.title);
        setErrorMessage(action.message);
        setDescriptionMessage(action.userDescription);
      }
      return false;
    }

    return true;
  };

  const handlePaymentFormSubmit = async (event) => {
    event.preventDefault();
    if (!checkDocumentStatus()) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/payment`,
        {
          method: "POST",
          body: JSON.stringify({ hash: hash, payment_method: selectedPayment }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        await response.json();
        setTitleDialogMessage("Success");
        setSuccessMessage(
          "I'm thankful. We have successfully sent your payment request. Please wait a little while; we will get back to you."
        );
        setIsSuccessDialogOpen(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Unknown error occurred");
        setTitleDialogMessage("Error");
        setIsErrorDialogOpen(true);
        setIsErrorPaymentHashDialogOpen(true);
      }
    } catch (error) {
      setErrorMessage("An error occurred during submission. Please try again.");
      setTitleDialogMessage("Submission Error");
      setIsErrorDialogOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDialogErrorPaymentHashClose = () => {
    setIsErrorPaymentHashDialogOpen(false);
  };
  // console.log(userData);
  useEffect(() => {
    if (userData?.paymenDatetime) {
      const givenTime = new Date(userData.paymenDatetime);

      const updateTimer = () => {
        const currentDate = new Date();
        const timeDifference = currentDate - givenTime;
        const minutesDifference = timeDifference / (1000 * 60);

        if (minutesDifference >= 20) {
          setExpiredTimer("Expired");
        } else {
          setExpiredTimer(
            `Time remaining: ${Math.round(20 - minutesDifference)} minutes`
          );
        }
      };

      updateTimer();

      const interval = setInterval(updateTimer, 1000);
      return () => clearInterval(interval);
    }
  }, [userData?.paymenDatetime]);

  const PaymentAlert = ({ expiredTimer }) => (
    <Grid item xs={12}>
      <MDBox mb={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Alert
          severity={expiredTimer === "Expired" ? "error" : "warning"}
          sx={{
            border: "2px dashed",
            borderColor: expiredTimer === "Expired" ? "red" : "orange",
            borderRadius: "8px", // Optional: Adds rounded corners
          }}
        >
          <AlertTitle>
            {expiredTimer === "Expired" ? "Timer Expired" : expiredTimer}
          </AlertTitle>
          {expiredTimer === "Expired"
            ? "Your time has expired; please try again later. Log out and log in again."
            : "Please hurry, as the timer will soon expire."}
        </Alert>
      </MDBox>
    </Grid>
  );

  const paymentData = {
    Tron: {
      image: TronHashImage,
      address: process.env.REACT_APP_TRANSACTIONCODE,
      label: "Pay USDT on Tron Blockchain",
    },
    ICBX: {
      image: ICBXADDRESSIMAGE,
      address: process.env.REACT_APP_ICBX_ADDRESS,
      label: "Pay ICBX on its Blockchain",
      coin_price: userData?.icbx_current_price,
    },
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Toaster />
      <MDBox pt={6} pb={3}>
        {loading ? (
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
          >
            <CircularProgress size={60} color="primary" />
            <Typography variant="h6" ml={2}>
              Loading your data...
            </Typography>
          </MDBox>
        ) : (
          <>
            {userData?.status && (
              <MDBox display="flex" justifyContent="center" mb={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="center">
                      <Marquee gradient={false} speed={130}>
                        <Alert
                          severity="success"
                          icon={
                            <CheckCircleIcon fontSize="inherit" color="info" />
                          }
                          variant="outlined"
                          sx={{
                            border: "2.5px solid #34601D",
                            borderRadius: "15px",
                            fontSize: "15px",
                          }}
                        >
                          <AlertTitle>
                            {PAYMENTSTATUSMESSAGES[userData.status]?.title ||
                              ""}
                          </AlertTitle>
                          {PAYMENTSTATUSMESSAGES[userData.status]?.message ||
                            ""}
                          {userData.description && (
                            <MDTypography color="error" variant="h4" mt={1}>
                              {userData.description}
                            </MDTypography>
                          )}
                        </Alert>
                      </Marquee>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            )}
            {userData?.isCoupon ? (
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: "50vh" }}
                mb={2}
              >
                <MDTypography variant="h3" style={{ color: "green" }}>
                  Coupon Applied Successfully 😊, Enjoy the ICB KYC without
                  Payment
                </MDTypography>
              </MDBox>
            ) : (
              <>
                <ProgressBar activeStep={4} />
                {userData?.paymenDatetime &&
                  userData.isPayment === false &&
                  ![
                    "Under_review",
                    "Processing",
                    "Block",
                    "Rejected",
                    "Completed",
                  ].includes(userData?.status) && (
                    <PaymentAlert expiredTimer={expiredTimer} />
                  )}
                <Grid container spacing={6} mt={1}>
                  <Grid item xs={12}>
                    <MDTypography
                      variant="h1"
                      sx={{ fontSize: "2rem" }}
                      align="center"
                    >
                      Make a payment to this address from any wallet.
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        value={selectedPayment}
                        onChange={(e) => setSelectedPayment(e.target.value)}
                      >
                        <FormControlLabel
                          value="Tron"
                          control={<Radio />}
                          label="Pay by Tron"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 50,
                            },
                            "& .MuiFormControlLabel-label": {
                              fontSize: "20px",
                              color: "#d1062a",
                            },
                          }}
                        />
                        <FormControlLabel
                          value="ICBX"
                          control={<Radio />}
                          label="Pay by ICBX"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 50,
                            },
                            "& .MuiFormControlLabel-label": {
                              fontSize: "20px",
                              color: "#d1062a",
                            },
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {selectedPayment && (
                    <>
                      <Grid item xs={12} textAlign="center">
                        <img
                          src={paymentData[selectedPayment].image}
                          alt="payment"
                          style={{
                            width: isMobile ? "35%" : 130,
                            cursor: "pointer",
                          }}
                        />
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mt={1}
                        >
                          <MDTypography
                            variant="h6"
                            sx={{ wordBreak: "break-all", maxWidth: "80%" }}
                          >
                            {paymentData[selectedPayment].address}
                          </MDTypography>
                          <Tooltip title="Copy">
                            <IconButton
                              onClick={() =>
                                handleAddressCopy(
                                  paymentData[selectedPayment].address,
                                  selectedPayment
                                )
                              }
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                        <MDTypography variant="h5" color="info" mt={2}>
                          {paymentData[selectedPayment].label}
                        </MDTypography>

                        {paymentData[selectedPayment].coin_price ? (
                          <MDTypography variant="h5" color="error" mt={2}>
                            {parseFloat(
                              paymentData[selectedPayment].coin_price
                            ).toFixed(2)}{" "}
                            ICBX
                          </MDTypography>
                        ) : (
                          ""
                        )}
                        <MDBox
                          component="form"
                          onSubmit={handlePaymentFormSubmit}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          gap={2}
                          mt={3}
                        >
                          <TextField
                            required
                            label="Transaction Hash"
                            placeholder="Enter or paste your transaction hash here"
                            // error={!!formError.hash}
                            // helperText={formError.hash}
                            value={hash}
                            onChange={(e) => setHash(e.target.value)}
                            disabled={expiredTimer === "Expired"}
                            sx={{ width: { xs: "90%", sm: "75%", md: "50%" } }}
                          />
                          <MDButton
                            type="submit"
                            variant="contained"
                            size="large"
                            disabled={expiredTimer === "Expired"}
                            sx={{
                              background:
                                "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                              color: "#fff",
                              "&:hover": { backgroundColor: "darkgreen" },
                            }}
                          >
                            {isSubmitting ? (
                              <MDBox>
                                <CircularProgress size={20} color="white" />
                                <MDTypography
                                  variant="text"
                                  color="white"
                                  ml={2}
                                >
                                  Submitting...
                                </MDTypography>
                              </MDBox>
                            ) : (
                              "Submit"
                            )}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </>
        )}
      </MDBox>
      <ErrorDialog
        open={isErrorDialogOpen}
        handleClose={handleDialogClose}
        title={titleDialogMessage}
        message={errorMessage}
        description={descriptionMessage}
      />
      <SuccessDialog
        open={isSuccessDialogOpen}
        handleClose={handleDialogSuccessClose}
        title={titleDialogMessage}
        message={successMessage}
        description={descriptionMessage}
        redirectUrl="/profile"
      />
      <PaymentHashErrorMsg
        openProp={isErrorPaymentHashDialogOpen}
        handleClose={handleDialogErrorPaymentHashClose}
      />
    </DashboardLayout>
  );
}

export default Payment;
