import { useState, useCallback } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useMediaQuery } from "react-responsive";
import bgImage from "assets/images/bg.png";
import { ethers } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import icon from "assets/images/icon.png";
import metamask from "assets/images/Metamask.png";
import logo from "assets/images/logo.png";
import EmailVerificationDialog from "components/Dialog/EmailVerification";

function Basic() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emailDialog, setEmailDialog] = useState(false);
  const [userAddress, setUserAddress] = useState(false);

  const onClickHandle = useCallback(async () => {
    if (typeof window.ethereum !== "undefined") {
      toast.success("MetaMask is connected!");
    } else {
      toast.error("MetaMask application is not connected, Please install it.");
    }

    setLoading(true);
    try {
      const [account] = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const checksumAddress = ethers.getAddress(account);

      const result = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}auth/check-address`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            walletAddress: checksumAddress,
          }),
        }
      ).then((res) => res.json());

      if (result.code === 1) {
        const message = `Sign this message to login. Wallet address: ${checksumAddress}`;
        const signature = await window.ethereum.request({
          method: "personal_sign",
          params: [
            ethers.hexlify(ethers.toUtf8Bytes(message)),
            checksumAddress,
          ],
        });

        const resultSign = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}auth/verify-signature`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              walletAddress: checksumAddress,
              signature: signature,
              message: message,
            }),
          }
        ).then((res) => res.json());

        if (resultSign.code === 1) {
          setUserAddress(resultSign.data.address);
          setEmailDialog(true);
        } else {
          setError(resultSign.error.message);
        }
      } else {
        setError(result.error.message);
      }
    } catch (err) {
      setError("An error occurred while connecting to MetaMask");
    } finally {
      setLoading(false);
    }
  }, []);

  //   useEffect(() => {
  //     if (emailDialog) {
  //       document.body.classList.add("popup-open");
  //     } else {
  //       document.body.classList.remove("popup-open");
  //     }
  //   }, [emailDialog]);

  const handleDialogSuccessClose = () => {
    setEmailDialog(false);
  };
  return (
    <BasicLayout image={bgImage}>
      <Toaster />
      <div className={emailDialog ? "blurred-background" : ""}>
        <Card
          sx={{
            backgroundColor: "#151515",
            boxShadow: 3,
            mx: 1,
            mt: 3,
            p: 5,
            mb: 1,
            textAlign: "center",
            border: "1px solid green",
          }}
        >
          {loading && <div className="loader"></div>}
          <MDBox>
            {!isMobile ? (
              <MDBox
                component="img"
                src={icon}
                alt="Logo"
                width="4rem"
                loading="lazy"
              />
            ) : (
              <MDBox
                component="img"
                src={logo}
                alt="Logo"
                width="10rem"
                loading="lazy"
              />
            )}

            <MDTypography
              variant="h2"
              fontWeight="medium"
              color="white"
              mt={2}
              mb={3}
            >
              Connect To ICB KYC
            </MDTypography>

            <MDTypography
              variant="h6"
              fontWeight="medium"
              mt={1}
              mb={4}
              sx={{ color: "red" }}
            >
              {error || ""}
            </MDTypography>
            <MDBox
              component="form"
              role="form"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <MDButton
                variant="gradient"
                onClick={onClickHandle}
                disabled={loading}
                sx={{
                  backgroundColor: "#323232",
                  color: "#fff",
                }}
              >
                <MDBox
                  component="img"
                  src={metamask}
                  alt="Logo"
                  width={40}
                  mr={1}
                />{" "}
                Continue with MetaMask
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
        <Card
          sx={{
            background: "transparent",
            boxShadow: 3,

            mt: 3,
            p: 1,
            mb: 1,
            textAlign: "center",
          }}
        >
          {" "}
          <MDBox color="white">
            {" "}
            <MDTypography
              variant="h5"
              fontWeight="medium"
              color="white"
              mt={2}
              mb={3}
            >
              KYC SmartContract{" "}
              <div
                style={{
                  whiteSpace: "nowrap",
                  fontSize: isMobile ? "14px" : "18px",
                }}
              >
                0xC9d7Aa7ff7b16aEbF82E58160D4d963a7Caf67ef
              </div>
            </MDTypography>
            {/* <MDTypography
            variant="h5"
            fontWeight="medium"
            color="white"
            mt={2}
            mb={3}
          >
            Check Your Address NFT ID{" "}
            <a
              href="https://icbscan.io/token/0xC9d7Aa7ff7b16aEbF82E58160D4d963a7Caf67ef"
              style={{ color: "#fff" }}
            >
              https://icbscan.io/token/0xC9d7Aa7ff7b16aEbF82E58160D4d963a7Caf67e
            </a>
          </MDTypography> */}
          </MDBox>
          <MDBox
            sx={{
              borderRadius: "15px",
              backgroundColor: "transparent",
              boxShadow: 3,
              mx: 1,
              mt: 3,
              p: 3,
              textAlign: "left",
              border: "1px solid white",
            }}
          >
            <MDTypography
              variant="h5"
              fontWeight="medium"
              color="white"
              mt={2}
              mb={3}
            >
              Need any help? Please send the issue along with a screenshot to
              <span
                style={{
                  fontWeight: "bold",
                  color: "#FFD700",
                  marginTop: "8px",
                  marginLeft: "3px",
                }}
              >
                <a
                  href="mailto:support@icbkyc.io"
                  style={{ color: "#FFD700", textDecoration: "underline" }}
                >
                  support@icbkyc.io
                </a>
              </span>
            </MDTypography>
            <MDTypography variant="h5" fontWeight="bold" color="white" mb={2}>
              📧 Email Format Guidelines:
            </MDTypography>

            <MDTypography variant="h6" fontWeight="medium" color="white">
              <b>Issue Explanation:</b> Describe your issue in detail.
            </MDTypography>

            <MDTypography variant="h6" fontWeight="medium" color="white">
              <b>Wallet Address:</b> Provide your wallet address.
            </MDTypography>

            <MDTypography variant="h6" fontWeight="medium" color="white">
              <b>Full Name:</b> Enter your first and last name.
            </MDTypography>

            <MDTypography variant="h6" fontWeight="medium" color="white">
              <b>Attachments:</b> Attach relevant screenshots of the issue.
            </MDTypography>

            <MDTypography variant="h6" fontWeight="bold" color="error" mt={4}>
              ⚠️ Important: If this format is not followed, your request may not
              be reviewed.
            </MDTypography>
          </MDBox>
        </Card>{" "}
      </div>
      {emailDialog && (
        <div className="popup-overlay">
          <EmailVerificationDialog
            openProp={emailDialog}
            handleClose={handleDialogSuccessClose}
            message="success"
            userAddress={userAddress}
          />
        </div>
      )}
    </BasicLayout>
  );
}

export default Basic;
