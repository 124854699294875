// @mui material components
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import DocumentImage from "assets/images/smallIcons/img1.png";
import FaceImage from "assets/images/smallIcons/img2.png";
import PhotoImage from "assets/images/smallIcons/img3.png";
import StatusImage from "assets/images/smallIcons/img5.png";
import PaymentImage from "assets/images/smallIcons/img4.png";
import MDButton from "components/MDButton";
import ProgressBar from "components/ProgressBar";
import DocumentDarkImage from "assets/images/icons/selected/Document.png";
import CameratDarkImage from "assets/images/icons/selected/Camera.png";
import PhotoDarkImage from "assets/images/icons/selected/Vector.png";
import PaymentDarkImage from "assets/images/icons/selected/beta.png";
import StatusDarkImage from "assets/images/icons/selected/Vector-1.png";
import HandshakeImage from "assets/images/handshake2.png";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import pattern from "assets/images/profile-bg.png";
import { PAYMENTSTATUSMESSAGES } from "constants";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
function Dashboard() {
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("token");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const dispatch = useDispatch();

  const { userDetails, loading } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getUserProfileData(token));
    } else {
      navigate("/login");
    }
  }, [dispatch, token, navigate]);

  useEffect(() => {
    if (userDetails !== null) {
      setUserData(userDetails);
    }
  }, [userDetails]);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        navigate("/");
        return;
      }
    };
    fetchData();
  }, [token, navigate]);

  useEffect(() => {
    if (userData && userData.status === "Completed") {
      setIsSuccessDialogOpen(true);
    }
  }, [userData]);

  const rawNftData = localStorage.getItem("nftdata");
  const nftMinting = !isNaN(rawNftData) ? rawNftData : 0;

  const handleRedirectLink = () => {
    navigate("/nft-payment");
  };
  const status = PAYMENTSTATUSMESSAGES[userData?.status] || {};
  const isActive =
    userData?.status === "Processing" || userData?.status === "Completed";
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {nftMinting > 0 ? (
        <MDBox py={3}>
          {loading ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <CircularProgress size={60} color="primary" />
              <Typography variant="h6" ml={2}>
                Loading your data...
              </Typography>
            </MDBox>
          ) : (
            <>
              <MDBox display="flex" justifyContent="center" mb={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="center">
                      <Marquee gradient={false} speed={130}>
                        <Alert
                          severity="success"
                          icon={
                            <CheckCircleIcon fontSize="inherit" color="info" />
                          }
                          variant="outlined"
                          sx={{
                            border: "2.5px solid #34601D",
                            borderRadius: "15px",
                            fontSize: "15px",
                          }}
                        >
                          <AlertTitle sx={{ fontSize: "16px" }}>
                            {" "}
                            Awesome! Your NFT is now minted and secured!
                          </AlertTitle>
                          Your Digital asset is officially minted and recorded.
                        </Alert>
                      </Marquee>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <Card
                sx={{
                  background:
                    "linear-gradient(0deg, #222222 48.62%, #1E3811 100%)",
                  position: "relative",
                }}
              >
                <MDBox
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  sx={{
                    backgroundImage: `url(${pattern})`,
                    backgroundSize: "cover",
                  }}
                />
                {[
                  {
                    hash: "paymentHash",
                    status: "paymentStatus",
                    tokenId: "tokenId",
                  },
                  {
                    hash: "paymentHashSecond",
                    status: "paymentStatusSecond",
                    tokenId: "tokenIdSecond",
                  },
                ].map(({ hash, status, tokenId }, index) => (
                  <MDBox position="relative" zIndex={2} p={2}>
                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", md: "row" }}
                      flexWrap="wrap"
                      justifyContent="space-between"
                      gap={1}
                      sx={{ mt: 2 }}
                    >
                      <MDBox
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                      >
                        <MDTypography
                          variant="h6"
                          color="white"
                          fontWeight="medium"
                        >
                          {index === 0 ? "First" : "Second"} Transaction Hash:
                          &nbsp;
                        </MDTypography>{" "}
                        <MDTypography
                          variant="h6"
                          color="white"
                          fontWeight="regular"
                          opacity={0.8}
                          sx={{
                            fontSize: 14,
                            wordBreak: "break-all",
                            maxWidth: "100%",
                          }}
                        >
                          {userData?.[hash] || "-"}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", md: "row" }}
                      flexWrap="wrap"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <MDBox
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                      >
                        <MDTypography
                          variant="h6"
                          color="white"
                          fontWeight="medium"
                        >
                          Payment Status: &nbsp;
                        </MDTypography>
                        <MDTypography
                          variant="body2"
                          color={
                            userData?.[status] === "Verified"
                              ? "success"
                              : userData?.[status] === "InCoupon"
                              ? "warning"
                              : userData?.[status] === "UnVerified"
                              ? "error"
                              : "white"
                          }
                          fontWeight="medium"
                        >
                          {userData?.[status] || "-"}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" alignItems="center">
                      <MDTypography
                        variant="h5"
                        color="white"
                        fontWeight="medium"
                      >
                        Token ID: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant="h5"
                        color="white"
                        fontWeight="regular"
                      >
                        {userData?.[tokenId] || "0"}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                ))}

                <MDBox
                  position="relative"
                  p={2}
                  m={2}
                  borderRadius="lg"
                  sx={{
                    border: "1px solid #fff",
                  }}
                >
                  <MDTypography color="white" variant="h6">
                    Apply for Second KYC?
                  </MDTypography>{" "}
                  <MDTypography color="white" variant="body2" mb={4}>
                    Don't miss out on this unique opportunity to own a
                    one-of-a-kind digital asset, and purchase now for you and
                    your loved-ones. Join us now!
                  </MDTypography>{" "}
                  <MDButton
                    onClick={() => handleRedirectLink()}
                    variant="contained"
                    size="large"
                    color="info"
                    disabled={
                      userData?.secondPaymentStatus === "Processing"
                        ? true
                        : false
                    }
                  >
                    Make payment for 2nd KYC
                  </MDButton>
                </MDBox>
              </Card>
            </>
          )}
        </MDBox>
      ) : (
        <MDBox py={3}>
          {loading ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <CircularProgress size={60} color="primary" />
              <Typography variant="h6" ml={2}>
                Loading your data...
              </Typography>
            </MDBox>
          ) : (
            <>
              {status.title && (
                <MDBox display="flex" justifyContent="center" mb={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Marquee gradient={false} speed={130}>
                        <Alert
                          severity="success"
                          icon={
                            <CheckCircleIcon fontSize="inherit" color="info" />
                          }
                          variant="outlined"
                          sx={{
                            border: "2.5px solid #34601D",
                            borderRadius: "15px",
                            fontSize: "15px",
                          }}
                        >
                          <AlertTitle>{status.title}</AlertTitle>
                          {status.message}
                          {userData?.description && (
                            <MDTypography color="error" variant="h4" mt={1}>
                              {userData.description}
                            </MDTypography>
                          )}
                        </Alert>
                      </Marquee>
                    </Grid>
                  </Grid>
                </MDBox>
              )}

              {!status.title && userData?.isCoupon && (
                <MDBox display="flex" justifyContent="center" mb={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="center">
                        <Marquee gradient={false} speed={130}>
                          <Alert
                            severity="success"
                            icon={
                              <LocalOfferIcon fontSize="inherit" color="info" />
                            }
                            variant="outlined"
                            sx={{
                              border: "2.5px solid #34601D",
                              borderRadius: "15px",
                              fontSize: "15px",
                            }}
                          >
                            <AlertTitle>Coupon Applied</AlertTitle>
                            Coupon Applied Successfully 😊, Enjoy the ICB KYC
                            without Payment
                          </Alert>
                        </Marquee>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              )}

              <ProgressBar activeStep={isActive ? 5 : 0} />

              <MDBox>
                <Grid container spacing={2} justifyContent="center">
                  {[
                    {
                      label: "Document Verification",
                      image: DocumentImage,
                      darkImage: DocumentDarkImage,
                    },
                    {
                      label: "Face Verification",
                      image: FaceImage,
                      darkImage: CameratDarkImage,
                    },
                    {
                      label: "Upload Photo",
                      image: PhotoImage,
                      darkImage: PhotoDarkImage,
                    },
                    {
                      label: "Payment Process",
                      image: PaymentImage,
                      darkImage: PaymentDarkImage,
                    },
                    {
                      label: "Check Status",
                      image: StatusImage,
                      darkImage: StatusDarkImage,
                    },
                  ].map((item) => (
                    <CardItem key={item.label} {...item} isActive={isActive} />
                  ))}
                </Grid>
                <MDBox textAlign="center" mt={10}>
                  <MDBox>
                    <Typography variant="h1">Welcome to ICB KYC</Typography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Please upload the related documents to continue
                    </MDTypography>
                  </MDBox>
                  <MDBox textAlign="center" mb={2}>
                    <Typography variant="h4">
                      Your Token ID is {userData?.tokenId}
                    </Typography>
                  </MDBox>
                  <MDBox>
                    <Button
                      variant="contained"
                      size="large"
                      component={Link}
                      to="/documents"
                      sx={{
                        background:
                          "linear-gradient(94deg, #50811E 0.13%, #172A0D 100.13%)",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "darkgreen",
                        },
                      }}
                    >
                      Continue
                    </Button>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" justifyContent="center" mb={4}>
                  <Dialog
                    open={isSuccessDialogOpen}
                    onClose={handleDialogSuccessClose}
                    aria-labelledby="error-dialog-title"
                    aria-describedby="error-dialog-description"
                    fullWidth={true} // Ensures the dialog stretches as per the content
                  >
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      p={6}
                    >
                      <img alt="document" src={HandshakeImage} width={100} />

                      <DialogTitle id="error-dialog-title" align="center">
                        Congratulation! 🥳
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText
                          id="error-dialog-description"
                          align="center"
                        >
                          We are happy 😊 to announce that after reviewing your
                          documents. You have successfullly based the KYC. We
                          will be providing NFT Soon on the wallet address you
                          have resigtered.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <MDButton
                          onClick={handleDialogSuccessClose}
                          variant="contained"
                          size="large"
                          sx={{
                            background:
                              "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "darkgreen",
                            },
                          }}
                        >
                          OK
                        </MDButton>
                      </DialogActions>
                    </MDBox>
                  </Dialog>
                </MDBox>
              </MDBox>
            </>
          )}
        </MDBox>
      )}
    </DashboardLayout>
  );
}

const CardItem = ({ image, darkImage, label, isActive }) => (
  <Grid item xs={12} md={6} lg={2}>
    <MDBox mb={3}>
      <Card
        sx={{
          border: `1px solid ${isActive ? "#3A8A11" : "#BCBCBC"}`,
          height: "100%",
          display: "grid",
          gridTemplateRows: "auto 1fr auto",
        }}
      >
        <MDBox padding="1rem" mt={1}>
          <MDBox display="flex" justifyContent="center">
            <img alt={label} src={isActive ? darkImage : image} />
          </MDBox>
          <MDBox
            pt={3}
            pb={1}
            px={1}
            sx={{
              textAlign: "center",
              color: isActive ? "#3A8A11" : "#BCBCBC",
            }}
          >
            <MDTypography variant="h6" textTransform="capitalize">
              {label}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  </Grid>
);

export default Dashboard;
