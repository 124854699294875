import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import DeniedImage from "assets/images/icons/Denied.png";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
function ErrorDialog({ open, handleClose, title, message, description }) {
  return (
    <MDBox display="flex" justifyContent="center" mb={4}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <MDBox display="flex" flexDirection="column" alignItems="center" p={6}>
          <img
            alt="document"
            src={DeniedImage}
            width={80}
            style={{ marginBottom: "16px" }}
          />
          <DialogTitle id="error-dialog-title" align="center">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="error-dialog-description" align="center">
              {message}
            </DialogContentText>
            <DialogContentText id="error-dialog-description" align="center">
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleClose}
              variant="contained"
              size="large"
              sx={{
                background:
                  "linear-gradient(94deg, #959595 0.13%, #959595 100.13%)",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
            >
              Ok
            </MDButton>
          </DialogActions>
        </MDBox>
      </Dialog>
    </MDBox>
  );
}

export default ErrorDialog;
