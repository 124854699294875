import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
// import pdffile from '../../sample.pdf'
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import ZoomInIcon from "@mui/icons-material/ZoomIn";
// import ZoomOutIcon from "@mui/icons-material/ZoomOut";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

function MyDocument(props) {
  // console.log('PDF URL', props.pdfFile)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // const [scale, setScale] = useState(1.0);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  // const zoomIn = () => {
  //   if (scale < 3.0) setScale(scale + 0.2);
  // };

  // // Zoom Out
  // const zoomOut = () => {
  //   if (scale > 0.5) setScale(scale - 0.2);
  // };

  return (
    <div className="pdf-div">
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <div style={{ height: "500px", overflowY: "auto" }}>
        <Document
          file={decodeURIComponent(props.pdfFile)}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            key={pageNumber}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
      <MDBox mt={2} sx={{ "& button": { m: 1 } }}>
        <MDButton
          onClick={prevPage}
          disabled={pageNumber === 1}
          variant="outlined"
          color="primary"
        >
          <ArrowBackIosNewIcon />
        </MDButton>
        <MDButton
          onClick={nextPage}
          disabled={pageNumber === numPages}
          variant="outlined"
          color="primary"
        >
          <ArrowForwardIosIcon />
        </MDButton>
        {/* <MDButton
          onClick={zoomOut}
          disabled={scale <= 0.5}
          variant="outlined"
          color="primary"
        >
          <ZoomOutIcon />
        </MDButton>
        <MDButton
          onClick={zoomIn}
          disabled={scale >= 3.0}
          variant="outlined"
          color="primary"
        >
          <ZoomInIcon /> */}
        {/* </MDButton> */}
      </MDBox>
    </div>
  );
}

export default MyDocument;
